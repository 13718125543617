import React, { useState, useEffect } from 'react';
import { AudioOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Pagination, Modal, Collapse, Input, Space, Table, Button, Dropdown, message, Tooltip, Select, Checkbox } from 'antd';
import { ProCard, ProFormGroup, ProFormSwitch } from '@ant-design/pro-components';
import { SearchOutlined } from '@ant-design/icons';

import axios from 'axios';
import '../components/style/Modal.css'; // 導入 Modal 的 CSS 樣式

const InventoryItemsManager = ({ isOpen, onClose, storeName }) => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('全部'); // 保存当前过滤状态

    const allVendors = ['全台', '央廚', '王座-備', '王座-食', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
    const allLayers = ['冷藏', '冷凍', '常溫', '清潔', '備品'];
    const { Search } = Input;
    const { Panel } = Collapse;

    const rowStyle = { height: '20px', padding: '5px', margin: '5px' };
    useEffect(() => {
        const fetchInventoryItems = async () => {
            try {
                const response = await axios.get(`https://inventory.edc-pws.com/api/products/${storeName}`);
                setInventoryItems(response.data);
            } catch (error) {
                console.error('Failed to fetch inventory items:', error);
            }
        };

        fetchInventoryItems();
    }, [storeName]);
    const handleCheckboxChange = (item, checked) => {
        const updatedItems = inventoryItems.map(i => {
            if (i.商品編號 === item.商品編號) {
                return { ...i, 庫別: checked ? '尚未選擇' : '未使用' }; // 根據勾選狀態更新庫別
            }
            return i;
        });

        setInventoryItems(updatedItems);
    };


    const handleInputChange = (productCode, field, value) => {
        const updatedItems = inventoryItems.map(item => {
            if (item.商品編號 === productCode) {
                return { ...item, [field]: value }; // 更新指定欄位
            }
            return item;
        });
        setInventoryItems(updatedItems);
    };

    const saveCompletedItems = async () => {
        try {
            await Promise.all(inventoryItems.map(async (item) => {
                await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${item.商品編號}`, {
                    庫別: item.庫別,
                    廠商: item.廠商,
                });
            }));
            message.success('數據提交成功！'); // 使用 Ant Design 的提示
            onClose(); // 提交成功后关闭模态框
        } catch (error) {
            console.error('提交數據失敗:', error);
            message.error('提交數據失敗！');
        }
    };
    // 切换过滤状态
    const toggleFilter = () => {
        setFilterStatus(prevStatus => {
            if (prevStatus === '全部') return '已設定';
            if (prevStatus === '已設定') return '未設定';
            return '全部';
        });
    };

    // 根据过滤状态和搜索词过滤显示的内容
    const filteredItems = inventoryItems.filter(item => {
        const matchesSearchTerm = item.商品名稱.includes(searchTerm) || item.商品編號.includes(searchTerm) || item.廠商.includes(searchTerm) || item.庫別.includes(searchTerm);

        if (filterStatus === '全部') return matchesSearchTerm;
        if (filterStatus === '已設定') return item.庫別 !== '未使用' && matchesSearchTerm;
        if (filterStatus === '未設定') return item.庫別 === '未使用' && matchesSearchTerm;
        return false;
    });
    // 篩選功能
    
       const getColumnSearchProps = (dataIndex) => ({
        // Custom column search logic (for text fields)
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    });
    // 設置表格的列
    const columns = [
        {
            title: '',
            dataIndex: '選擇',
            render: (_, item) => (
                <Checkbox
                    checked={item.庫別 !== '未使用'} // 根據庫別值設定勾選狀態
                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                />
            ),
            width: '33px',
            height: 20,
            align: 'center',
        },
        {
            title: '編號',
            id: '編號',
            dataIndex: '商品編號',
            render: (_, item) => item.商品編號,
            width: '0px',
            height: 20,
            align: 'center',
            display: 'none',
            ellipsis: true,

        },

        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            render: (_, item) => item.商品名稱,
            width: '100%',
            align: 'left',
            height: 'auto',
            ...getColumnSearchProps('商品名稱'),
        },
        {
            title: '廠商',
            dataIndex: '廠商',
            render: (_, item) => (
                <Select style={{width: '90px', border: '0px', padding: '0' }}
                    value={item.廠商}
                    onChange={value => handleInputChange(item.商品編號, '廠商', value)}
                    disabled={item.庫別 === '未使用'} // 根據庫別值控制欄位可編輯性
                >
                    <Select.Option value="" disabled>尚未選擇</Select.Option>
                    {allVendors.map(vendor => (
                        <Select.Option key={vendor} value={vendor}>{vendor}</Select.Option>
                    ))}
                </Select>
            ),
            align: 'center',
            width: '100px',
        },
        {
            title: '庫別',
            dataIndex: '庫別',
            render: (_, item) => (
                <Select style={{ width: '90px', border: '0px', padding: '0' }}
                    value={item.庫別}
                    onChange={value => handleInputChange(item.商品編號, '庫別', value)}
                    disabled={item.庫別 === '未使用'} // 根據庫別值控制欄位可編輯性
                >
                    <Select.Option value="未使用" disabled>尚未選擇</Select.Option>
                    {allLayers.map(layer => (
                        <Select.Option key={layer} value={layer}>{layer}</Select.Option>
                    ))}
                </Select>
            ),
            width: '100px',
            align: 'center',
            height: 20,

        },
    ];

    if (isOpen) {
        return (
            <Modal
                title=""
                visible={isOpen}
                onCancel={onClose}

                footer={[
                    <Button key="submit" onClick={saveCompletedItems} type="primary">
                        提交資料
                    </Button>,
                    <Button key="cancel" onClick={onClose}>
                        取消
                    </Button>,
                ]}

            >
                <h1>盤點品項管理</h1>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <Space style={{ width: '100%', marginBottom: '10px' }}>
                    <Button onClick={toggleFilter}>
                        {filterStatus} {/* 显示当前过滤状态 */}
                    </Button>
                    <Input
                        placeholder="搜尋..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Space>
                </div>
                <Table
                    id="custom-scrollbar"
                    
                    dataSource={filteredItems}
                    columns={columns}
                    rowKey="商品編號"
                    pagination={true}
                    size="small"
                    scroll={{ x: 200, y: 250 }} // 设置固定表头
                />
            </Modal>
        );
    }

    return null; // 若未开启模态则返回 null
};

export default InventoryItemsManager;
