import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Modal, message, Input, Checkbox, Button } from 'antd';
import axios from 'axios';
import { exportToExcel } from './ExportToExcel';
import Swal from 'sweetalert2';


const ArchiveModal = forwardRef(({ isOpen, onClose, products, storeName, props }, ref) => {
    const [password, setPassword] = useState('');
    const [emptyQuantityProducts, setEmptyQuantityProducts] = useState([]);
    const [isArchived, setIsArchived] = useState(false);
    const [isCleared, setIsCleared] = useState(false); // 用于跟踪清除状态
    const [checked, setChecked] = useState(false); // 用于同意确认

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = currentDate.getDate();
    const adjustedMonth = day < 16
        ? (month === '01' ? '12' : String(parseInt(month) - 1).padStart(2, '0'))
        : month;

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            handleSubmit();
        }
    }));

    const checkEmptyQuantities = () => {
        const emptyProducts = products.filter(product =>
            product.庫別 !== '未使用' && (!product.數量)
        );
        setEmptyQuantityProducts(emptyProducts);
    };

    const handleSubmit = async (action) => {
        // 检查是否有空数量的产品
        checkEmptyQuantities();

        if (emptyQuantityProducts.length > 0) {
            const productNames = emptyQuantityProducts.map(p => p.商品名稱).join(', ');
            const result = await Swal.fire({
                title: '警告！',
                html: `<p>以下產品的數量為空：</p><div style="color: red; margin-top: 10px; text-align: left">${productNames}</div>`,
                text: '請問您是否繼續',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否'
            });

            if (result.isConfirmed) {
                await toArchive();
            }
            return;
        }

        if (action === 'archive') {
            await toArchive();
        } else if (action === 'clear') {
            await clearInventory();
        }
    };

    const toArchive = async () => {
        try {
            const response = await axios.post(`https://inventory.edc-pws.com/api/archive/${props.storeName}`, { password:  password }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            await exportToExcel(products, year, adjustedMonth, true);

            message.success('歸檔成功：' + JSON.stringify(response.data));
            setIsArchived(true);
            resetStates();
            onClose();
        } catch (error) {
            console.error('歸檔請求失敗:', error);
            message.error('歸檔失敗：' + (error.response ? error.response.data : error.message));
            resetStates();
        }
    };

    const clearInventory = async () => {
        try {
            const encodedStoreName = encodeURIComponent(storeName);
            const response = await axios.post(`https://inventory.edc-pws.com/api/clear/${encodedStoreName}`, {
                password: password, // 假设您需要传递密码
            });
            message.success('清除盤點成功：' + JSON.stringify(response.data));
            setIsCleared(true);
            resetStates();
            onClose();
        } catch (error) {
            console.error('清除請求失敗:', error);
            message.error('清除失敗：' + (error.response ? error.response.data : error.message));
            resetStates();
        }
    };

    const resetStates = () => {
        setPassword('');
        setEmptyQuantityProducts([]);
        setIsArchived(false); // 重置归档状态
        setIsCleared(false); // 重置清除状态
        setChecked(false); // 重置复选框状态
    };

    useEffect(() => {
        if (isOpen) {
            resetStates(); // 确保每次打开模态框时重置状态
        }
    }, [isOpen]);

    return (
        <Modal
            title="盤點結束"
            visible={isOpen}
            onCancel={onClose}
            footer={null} // 不使用 Ant Design 的默认底部按钮
            centered
            width={500} // 可根据需要调整宽度
        >
            <div style={{ marginBottom: '10px' }}>
                <h4>請輸入管理員密碼。</h4>
                <Input.Password
                    placeholder="輸入管理員密碼"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <div style={{ color: 'red', margin: '10px 0' }}>
                {emptyQuantityProducts.length > 0 && (
                    <span>注意：有以下商品的数量为空，请确认后再继续操作。</span>
                )}
            </div>

            <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                style={{ marginTop: 10 }}
            >
                我已確認所有數值正確無誤，且明白此操作不可逆。
            </Checkbox>

            <div style={{ marginTop: '20px' }}>
                <Button
                    type="primary"
                    onClick={() => handleSubmit('archive')}
                    disabled={!checked || !password} // 如果未勾选则禁用按钮
                    style={{ marginRight: '10px' }}
                >
                    盤點歸檔
                </Button>
                <Button danger
                    onClick={() => handleSubmit('clear')}
                    disabled={!checked || !password} // 如果未勾选则禁用按钮
                >
                    盤點清除
                </Button>
            </div>
        </Modal>
    );
});

export default ArchiveModal;

