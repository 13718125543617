import React from 'react';
import './style/CalculatorButton.css'; // 导入按钮的 CSS 样式

const CalculatorButton = ({ label, onClick, type, children }) => {
    return (
        <button
            onClick={onClick}
            className={`digit ${type ? type : ''}`} // 根据 type 添加额外的样式
        >
            {children} {/* 渲染孩子，例如图标 */}
            {label && <span>{label}</span>} {/* 渲染标签文本 */}
        </button>
    );
};

export default CalculatorButton;
