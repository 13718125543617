// app.js
import React, { useEffect, useState, useRef } from 'react';
import { Spin, Table, Result, Divider, Popover ,notification, Typography, Progress, InputNumber, Drawer, DatePicker, Input, Space, Button } from 'antd';
import { SearchOutlined, FileDoneOutlined } from '@ant-design/icons';

import axios from 'axios';
import io from 'socket.io-client';
import Swal from 'sweetalert2'
import ExportModal from './components/ExportModal';
import ArchiveModal from './components/ArchiveModal';
import GuideModal from './components/GuideModal';
import Modal from './components/Modal';
import Calculator from './components/Calculator';
//import FilterModal from './components/FilterModal';
import BouncyComponent from './components/BouncyComponent';
import InventoryUploader from './components/InventoryUploader';
import InventoryItemsManager from './components/InventoryItemsManager';
import dayjs from 'dayjs';

import { setCookie, getCookie } from './utils/cookie';
import './components/style/Modal.css'; // 導入 Modal 的 CSS 樣式
import './components/style/Calculator.css'; // 導入 Modal 的 CSS 樣式
//import dayjs from 'dayjs';
const socket = io('https://inventory.edc-pws.com'); //  連線到 Socket.IO 伺服器
const { Text, Link } = Typography;

const App = () => {
    const tableRef = useRef(null);
    const inputNumberRefs = useRef([]);
    const rowRefs = useRef([]); // 添加对行的引用

    // 狀態變數
    const [archivePassword, setArchivePassword] = useState(''); // 存儲歸檔密碼
    const [hasProducts, setHasProducts] = useState(false); // 新增狀態變數來追蹤產品列表是否存在
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('所有品項'); // 初始状态为所有品项
    const [showFunctionButtons, setShowFunctionButtons] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState('連線中...');
    const [showGuide, setShowGuide] = useState(false);
    const [isOffline, setIsOffline] = useState(false);
    const [errorModal, setErrorModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState('小算盤模式'); // 初始模式設置

    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isInventoryItemsManagerOpen, setIsInventoryItemsManagerOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const cookieName = 'inventoryGuideShown';
    const [isReconnectPromptVisible, setIsReconnectPromptVisible] = useState(false);
    const inputRefs = useRef([]);
    const [hoveredProduct, setHoveredProduct] = useState(null);
    const [initialStock, setInitialStock] = useState(''); // 用於存儲期初庫存
    const [currentSpec, setCurrentSpec] = useState(''); // 用於存儲規格
    const [currentunit, setCurrentunit] = useState(''); // 用於存儲單位
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [modalContent, setModalContent] = useState({});
    const [userCount, setUserCount] = useState(0);
    const archiveModalRef = useRef();
    const inventoryUploaderRef = useRef();
    const [storeName, setStoreName] = useState('新店京站');
    const [showCalculator, setShowCalculator] = useState(false);
    const allVendors = ['全台', '央廚', '王座-備', '王座-食', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
    const allLayers = ['未使用', '冷藏', '冷凍', '常温', '清潔', '備品'];
    const stores = ['台北京站', '新店京站', '信義威秀'];
    const disabledVendors = ['忠欣', '王座-備'];
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [vendorStatistics, setVendorStatistics] = useState({}); // 儲存廠商統計

    const [currentProductIndex, setCurrentProductIndex] = useState(null);
    const [calculatorVisible, setCalculatorVisible] = useState(false);
    const [currentProductCode, setCurrentProductCode] = useState(null);
    const [currentProductName, setCurrentProductName] = useState(''); // 新增状态来存储商品名称
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const quantityInputRefs = useRef([]);
    const expiryDateInputRefs = useRef([]); // 到期日輸入框的引用
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const [api, contextHolder] = notification.useNotification();

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const calculateVendorTotals = () => {
        const filteredProducts = products.filter(product => product.庫別 !== '未使用');

        // 使用 reduce 來按廠商分組
        const vendorTotals = filteredProducts.reduce((acc, product) => {
            const vendor = product.廠商;

            if (!acc[vendor]) {
                acc[vendor] = { totalValid: 0, totalWithQuantities: 0 };
            }

            acc[vendor].totalValid += 1;
            if (product.數量 > 0) {
                acc[vendor].totalWithQuantities += 1;
            }

            return acc;
        }, {});

        // 計算每個廠商的百分比
        for (const vendor in vendorTotals) {
            const { totalValid, totalWithQuantities } = vendorTotals[vendor];
            vendorTotals[vendor].percentage = totalValid > 0 ? ((totalWithQuantities / totalValid) * 100).toFixed(2) : 0;
        }

        return vendorTotals;
    };

    // 產品數據加載函數

    useEffect(() => {

        /*const fetchCsrfToken = async () => {
            try {
                const response = await axios.get('https://inventory.edc-pws.com/api/csrf-token');
                return response.data.csrfToken; // 確保根據 API 返回格式正確處理
            } catch (error) {
                console.error('Failed to fetch CSRF token:', error);
                return null; // 返回 null 或處理錯誤
            }
        };*/

        const fetchProducts = async () => {
            setLoading(true);
            await delay(500); // 等待0.5秒
            try {
                //const csrfToken = await fetchCsrfToken(); // 獲取 CSRF 令牌
                const response = await axios.get(`https://inventory.edc-pws.com/api/products/${storeName}`);
                if (response.status === 100) {
                    setIsOffline(false);
                    setLoading(false);
                } else {
                    const updatedProducts = response.data.map(product => ({
                        ...product,
                        isExpiryDateEditable: !disabledVendors.includes(product.廠商), // 根據禁用廠商設定可編輯性
                    }));
                    setProducts(updatedProducts);
                    setIsOffline(false);
                    setHasProducts(true);


                }
            } catch (error) {
                console.error('取得產品失敗:', error);
                setIsOffline(true);
            } finally {
                setLoading(false);
            }
        };



        const guideShown = getCookie(cookieName); // 檢查 cookie 是否存在
        if (!guideShown) {
            // 如果 cookie 不存在，顯示說明手冊
            setTimeout(() => {
                setShowGuide(true);
                setCookie(cookieName, 'true'); // 設定 cookie，防止再顯示
            }, 1000);
        }

        if (storeName) {
            socket.emit('joinStoreRoom', storeName);
        }
        // 當 storeName 更改時，發送用戶加入房間
        // Socket.IO 事件監聽
        socket.on('updateUserCount', setUserCount);
        socket.on('productUpdated', (updatedProduct) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                )
            );

            api.open({
                message: '商品數量更新',
                description: `${updatedProduct.商品名稱} 數量變更為 ${updatedProduct.數量}`,
                icon: (
                    < FileDoneOutlined style={{ color: '#52c41a' }} />
                ),
                duration: 2, // 持續時間，可以根據需要調整
                showProgress: true,
                pauseOnHover: false,
                style: {

                }
            });
        });

        if (products.length > 0) {
            const vendorTotals = calculateVendorTotals();
            console.log(vendorTotals);
            // 可以將此數據存在 state 中，供渲染使用
            setVendorStatistics(vendorTotals);
        }
        fetchProducts(); // 調用 fetchProducts 函數`
        socket.emit('joinStoreRoom', storeName);


        // 網路連線狀態監聽 (需要根據瀏覽器環境調整)
        const handleOnline = () => {
            Swal.fire('通知', '連線成功', 'success');
            setIsOffline(false);
        };
        const handleOffline = () => {
            Swal.fire('通知', '失去連線', 'warning');
            setIsOffline(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        // 清除函式
        return () => {
            socket.off('updateUserCount', setUserCount);
            socket.off('productUpdated');
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            socket.disconnect();
        };
    }, [storeName, setHasProducts]); // 將 setHasProducts 添加到依賴項中


    const handleStoreChange = (event) => {
        const newStoreName = event.target.value;
        setStoreName(newStoreName);
        socket.emit('joinStoreRoom', newStoreName); // 加入相應的房間
    };


    const startInventory = () => {
        if (inventoryUploaderRef.current) {
            inventoryUploaderRef.current.startInventory(); // 調用子組件的方法
        }
    };


    // 錯誤處理函式
    const handleError = (error, defaultMessage) => {
        const errorMessage = error.response ? error.response.data.message || error.response.data : error.message || defaultMessage;
        setErrorModal({ title: '錯誤訊息', message: errorMessage });
        setIsModalOpen(true);
    };


    const handleReconnect = () => {
        setConnectionStatus('連接成功 ✔');
        setIsUserOffline(false);
        setIsReconnectPromptVisible(false);

    };

    const handleReload = () => {
        window.location.reload(); // 重新加載頁面
        setIsModalOpen(false);
    };

    // 控制廠商篩選
    const handleVendorChange = (vendor) => {
        setSelectedVendors((prev) =>
            prev.includes(vendor) ? prev.filter(v => v !== vendor) : [...prev, vendor]
        );
    };

    // 控制庫別篩選
    const handleLayerChange = (layer) => {
        setSelectedLayers((prev) =>
            prev.includes(layer) ? prev.filter(l => l !== layer) : [...prev, layer]
        );
    };



    //Enter切換下一個輸入框
    const handleKeyPress = (event, index) => {
        
            const nextInput = inputNumberRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus(); // 将焦点移到下一行的输入框
            }
        
    };
    //下載最新數量
    const updateQuantity = async (productCode, quantity) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/quantity`, { 數量: quantity });
        } catch (error) {
            console.error("更新產品時出錯:", error);
        }
    };
    //下載最新校期
    const updateExpiryDate = async (productCode, expiryDate) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/expiryDate`, { 到期日: expiryDate });
        } catch (error) {
            console.error("更新到期日時出錯:", error);
        }
    };

    // 上傳數量
    const handleQuantityChange = (productCode, quantity) => {
        const numQuantity = Number(quantity);
        if (isNaN(numQuantity) || numQuantity < 0) {
            alert('數量必須為非負數');
            return;
        }

        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 數量: numQuantity }; // 更新數量
            }
            return product;
        });

        setProducts(updatedProducts);
        updateQuantity(productCode, numQuantity); // 上傳數量到資料庫
    };

    // 上傳到期日
    const handleExpiryDateChange = (productCode, expiryDate) => {
        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 到期日: expiryDate }; // 更新到期日
            }
            return product;
        });

        setProducts(updatedProducts);
        updateExpiryDate(productCode, expiryDate); // 上傳到期日到資料庫
    };

    const handleMouseEnter = (product, e) => {
        setHoveredProduct(product.商品編號);
        // 直接使用 product 來設置初始庫存、規格和單位
        setInitialStock(product.期初庫存 ? `${product.期初庫存} ${product.單位}` : '查無歷史盤點紀錄');
        setCurrentSpec(product.規格 ? product.規格 : '未設定');
        setCurrentunit(product.單位 ? product.單位 : '');
        // 獲取當前鼠標位置
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltipPosition({ top: e.clientY + 10, left: e.clientX + 10 });
    };

    const handleMouseLeave = () => {
        setHoveredProduct(null); // 清除懸停商品
        setInitialStock('');
        setCurrentSpec('');
        setCurrentunit('');
    };


    const openArchiveModal = () => {
        setIsArchiveModalOpen(true); // 打开模态框
    };

    const closeArchiveModal = () => {
        setIsArchiveModalOpen(false); // 关闭模态框
    };

    const toggleMode = () => {
        setMode(prevMode => {
            const newMode = prevMode === '小算盤模式' ? '直接輸入模式' : '小算盤模式';
            if (newMode === '直接輸入模式') {
                setCalculatorVisible(false); // 切換至直接輸入模式時隱藏計算機
            }
            return newMode;
        });
    };


    const setFocusToNextInput = (currentIndex) => {
        const nextInput = inputNumberRefs.current[currentIndex + 1]; // 找到下一个输入框
        if (nextInput) {
            nextInput.focus(); // 聚焦下一个输入框
        }
    };
        const preventInput = (e) => {
        if (mode === '小算盤模式') {
            e.preventDefault(); // 阻止用戶輸入
            e.stopPropagation(); // 阻止事件傳播
                        // 添加全局提示
            api.info({
                message: '使用提示',
                description: '請使用頁面上的小算盤進行輸入，或切換至直接輸入模式。',
                duration: 2, // 提示持續時間，可根據需要調整
            });
        }
    };
    const handleQuantityClick = (product, index) => {
        if (mode === '小算盤模式') {
            setCurrentProductCode(product.商品編號); // 保存当前选中的产品编号
            setCurrentProductName(product.商品名稱); // 更新当前商品名称
            setCalculatorVisible(true); // 显示计算机
            const currentInput = inputNumberRefs.current[index];
            if (currentInput) {
                currentInput.focus(); // 如果需要，确保聚焦
            }
        }
    };



    const handleCalculate = (result) => {
        if (currentProductCode) {
            const updatedProducts = products.map((product) => {
                if (product.商品編號 === currentProductCode) {
                    updateQuantity(product.商品編號, result); // 更新同步到服务器
                    return { ...product, 數量: result }; // 更新选中的产品的数量
                }
                return product; // 返回其他不变的产品
            });
            setProducts(updatedProducts); // 更新状态
        }
      

    };

    const handleCloseCalculator = () => {
        setCalculatorVisible(false); // 關閉計算機
    };





    const toggleFilter = (status) => {
        setFilterStatus(status); // 直接使用传入的状态
    };


    // 根据所选的厂商、库别和搜索关键词过滤产品
    const filteredProducts = products.filter(product => {
        const vendorMatch = selectedVendors.length === 0 || selectedVendors.includes(product.廠商); // 材商匹配
        const layerMatch = selectedLayers.length === 0 || selectedLayers.includes(product.庫別); // 库别匹配
        const matchesSearchTerm = product.商品名稱.includes(searchTerm); // 搜索关键词匹配

        // 根据过滤状态返回相应的过滤逻辑
        if (filterStatus === '所有品項') {
            return vendorMatch && layerMatch && matchesSearchTerm; // 所有条件都满足
        }
        if (filterStatus === '已盤點品項') {
            return product.數量 >= 0 && vendorMatch && layerMatch && matchesSearchTerm; // 数量不为空且满足其他条件
        }
        if (filterStatus === '未盤點品項') {
            return product.數量 == null && vendorMatch && layerMatch && matchesSearchTerm; // 数量为空且满足其他条件
        }
        return false; // 默认情况下不返回任何结果
    });

    // 篩選功能
    const getColumnSearchProps = (dataIndex) => ({
        // Custom column search logic (for text fields)
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    });

    const columns = [
        {
            title: '商品編號',
            dataIndex: '商品編號',
            key: '商品編號',
            id: '編號',
            width: '15%',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            key: '商品名稱',
            width: '150px',
            align: 'left',
            render: (text, product) => (
                <div
                    onMouseEnter={(e) => handleMouseEnter(product, e)} // 悬停事件处理
                    onMouseLeave={handleMouseLeave} // 离开事件处理
                >
                    {text}
                </div>
            ),
            ...getColumnSearchProps('商品名稱'), // 保持搜索功能不变
        },
        {
            title: '數量',
            dataIndex: '數量',
            key: '數量',
            render: (text, product, index) => (
                <>
                <Popover
                        content={
                            <Calculator
                    onCalculate={(result) => handleCalculate(result)}
                    onClose={handleCloseCalculator}
                    productName={currentProductName}
                    currentIndex={currentProductIndex} // 传递当前行的索引
                    setFocusToNextInput={setFocusToNextInput} // 传递设置焦点的函数

                />
                        }
                        title=""
                        trigger="focus"
                        visible={calculatorVisible && currentProductIndex === index} // 只有在當前項目中顯示
                        onVisibleChange={(visible) => {
                            if (!visible) {
                                setCalculatorVisible(false); // 隱藏計算機
                            }
                        }}
                    >
                    <InputNumber
                        value={product.數量} // 确保使用 item.數量
                        ref={el => inputNumberRefs.current[index] = el} // 使用单个 ref 数组管理
                        onKeyDown={preventInput}
                        onChange={(value) => {
                                // 直接輸入模式下可以正常更改數量
                                if (mode === '直接輸入模式') {
                                    handleQuantityChange(product.商品編號, value);
                                }
                            }}
                        onClick={() => { setCurrentProductIndex(index); handleQuantityClick(product, index) }} // 使用 index
                        onFocus={() => { setCurrentProductIndex(index); handleQuantityClick(product, index) }} // 使用 index
                        onPressEnter={(e) => handleKeyPress(e, index)} // 按下 Enter 时调用
                        style={{ width: '60px', textAlign: 'right' }}
                    />
                    &nbsp;{product.單位}
                    </Popover>
                </>
            ),
            width: '60px',
            align: 'center',

        },
        {
            title: '到期日',
            dataIndex: '到期日',
            key: '到期日',
            render: (text, product, index) => (
                <DatePicker
                    defaultValue={product.到期日 ? dayjs(product.到期日) : null}
                    onChange={(date, dateString) => handleExpiryDateChange(product.商品編號, dateString)}
                    style={{
                        backgroundColor: product.isExpiryDateEditable ? 'white' : '#f0f0f0',
                    }}
                    ref={el => (expiryDateInputRefs.current[index] = el)}
                    disabled={!product.isExpiryDateEditable}
                    format="YYYY-MM-DD"
                />
            ),
            width: '60px',
            align: 'center',
        },
    ];
    return (
        <>

            <Drawer title="篩選" width='auto' placement="left" onClose={onClose} open={open}>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '10px', margin: '5px', overflowX: 'auto' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                        <strong>廠商</strong>
                        <div className="list" style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', alignItems: 'flex-start', marginLeft: '10px' }}>

                            {allVendors.map(vendor => {
                                const { totalValid, totalWithQuantities, percentage } = vendorStatistics[vendor] || { totalValid: 0, totalWithQuantities: 0, percentage: 0 }; // 確保取得正確的數據
                            return (

                                <label key={vendor} className="filter-item" style={{ display: 'flex', margin: '5px 0', alignItems: 'center' }} >
                                    <input
                                        style={{ alignItems: 'flex-end', marginRight: '8px' }}
                                        type="checkbox"
                                        checked={selectedVendors.includes(vendor)}
                                        onChange={() => handleVendorChange(vendor)}
                                    />
                                    {vendor}
                                    <Progress percent={percentage} style={{ marginLeft: '10px', width: '100px' }} /> {/* 顯示百分比 */}
                            </label>
                                );
                            })}
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', marginTop: '10px' }}>
                        <strong>庫別</strong>
                        <div className="list" style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', alignItems: 'flex-start', marginLeft: '10px' }}>

                            {allLayers.map(layer => (
                                <label key={layer} className="filter-item" style={{ display: 'flex', margin: '5px 0', alignItems: 'center' }} >
                                    <input
                                        style={{ alignItems: 'flex-end', marginRight: '8px' }}
                                        type="checkbox"
                                        checked={selectedLayers.includes(layer)}
                                        onChange={() => handleLayerChange(layer)}
                                    />
                                    {layer}
                                </label>
                            ))}
                        </div>
                    </div>
                </div >

            </Drawer>
            {contextHolder}

            {/* 其他 Modal 與提示框 */}
            <InventoryUploader ref={inventoryUploaderRef} storeName={storeName} />

            <ArchiveModal
                isOpen={isArchiveModalOpen}
                onClose={closeArchiveModal}
                products={products}
                storeName={storeName}
            />
            <InventoryItemsManager
                isOpen={isInventoryItemsManagerOpen}
                onClose={() => setIsInventoryItemsManagerOpen(false)}
                products={products}
                storeName={storeName} />

            <ExportModal
                isOpen={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                products={products}
            />
            <GuideModal isOpen={showGuide} onClose={() => setShowGuide(false)} />
            {/* 顯示工具提示 */}
            {hoveredProduct && (<div style={{ textAlign: 'left', fontSize: '12px', position: 'fixed', backgroundColor: 'white', border: '1px solid #ccc', padding: '5px', borderRadius: '5px', zIndex: 1000, top: tooltipPosition.top, left: tooltipPosition.left, }}>
                期初存量：{initialStock} <br />
                規格：{currentSpec}
            </div>
            )}
            {/* 顯示載入提示*/}

            <Modal
                isOpen={isModalOpen}
                title={modalContent.title}
                message={modalContent.message}
                type={modalContent.type}
                onClose={() => setIsModalOpen(false)}
            />
            {/* 顯示錯誤訊息的 Modal */}
            {errorModal && (
                <Modal
                    isOpen={!!errorModal}
                    title={errorModal.title}
                    message={errorModal.message}
                    onClose={() => setErrorModal(null)}
                    type="error"
                />
            )}
            {/* 顯示離線提示 */}
            {isOffline && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000, }}>
                    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                        <h2>您已離線</h2>
                        <p>請檢查網路連線是否正常。</p>
                        <button onClick={() => window.location.reload()}>重新整理</button>
                    </div>
                </div>

            )
            }

            {/* 閒置提示框，顯示重新上線按鈕 */}
            {
                isReconnectPromptVisible && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000, }}>
                        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                            <h2>閒置中斷線</h2>
                            <p>您已閒置超過10分鐘，請重新連接。</p>
                            <button onClick={handleReconnect}>重新上線</button>
                        </div>
                    </div>
                )
            }
            <div className="inventory-header">
                <div className="header-container"style={{ width: '100%' }}>
                    <table className="header-table">
                        <thead>
                            <tr>
                                <td style={{ width: '60%' }} >
                                    <h1>庫存盤點系統</h1>
                                    {storeName === 'noStart' ? '檢查中...' : (isOffline ? <font color="red">失去連線 ❌</font> : <font color="#52c41a">連線成功 ✔</font>)}&nbsp;|&nbsp;在線共&nbsp;<strong>{userCount}</strong>&nbsp;人
                                </td>
                                {/*<td rowSpan="2">
                                    <select value={storeName} onChange={handleStoreChange} >
                                        <option value="" disabled>請選擇門市</option>
                                        {stores.map((store, index) => (
                                            <option key={index} value={store}>{store}</option>
                                        ))}
                                    </select>
                                </td>*/}
                                <td style={{ width: '40%' }} >
                                    <>

                                        {products.length === 0 ? (
                                            <Space size="small">
                                                <Button onClick={startInventory} type="primary"> 盤點開始 </Button>
                                                <Button className="header-button" onClick={() => setShowGuide(true)} type="default" > 說明 </Button>
                                            </Space>
                                        ) : (
                                            <Space size="small">
                                                <Button className="header-button" onClick={showDrawer} type="default" >篩選</Button>
                                                <Button className="header-button" onClick={() => setIsExportModalOpen(true)} type="default"> 匯出 </Button>
                                                <Button className="header-button" onClick={() => setShowGuide(true)} type="default" > 說明 </Button>
                                            </Space>
                                        )}

                                        <div style={{ height: '10px' }} ></div>

                                        {products.length !== 0 &&
                                            <Space size="small">
                                                <Button className="header-button" onClick={() => setIsInventoryItemsManagerOpen(true)} type="primary"> 品項管理 </Button>
                                                <Button danger onClick={openArchiveModal} type="primary">盤點結束</Button>
                                            </Space>}
                                    </>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div style={{ height: '16vh' }} ></div>

            {loading && <div><Spin fullscreen /></div>
            }

            {products.length === 0 ? (
                <Result
                    status="warning"
                    title="無法盤點，因為尚未開始。"
                    extra={
                        <Space>
                        <Button type="primary" onClick={startInventory}>
                            開始盤點
                            </Button>
                        </Space>
                    }
                />
            ) : (

                    <form autoComplete='no'>

                    <div style={{ maxWidth: '725px', margin: '0 20px', overflowX: 'auto' }}>
                            <Space style={{ width: '100%', marginBottom: '10px' }}>
                                    <Button
                                        type={filterStatus === '所有品項' ? 'primary' : 'default'}
                                        onClick={() => toggleFilter('所有品項')}
                                    >
                                        所有品項
                                    </Button>
                                    <Button
                                        type={filterStatus === '未盤點品項' ? 'primary' : 'default'}
                                        onClick={() => toggleFilter('未盤點品項')}
                                    >
                                        未盤點品項
                                    </Button>
                                    <Button
                                        type={filterStatus === '已盤點品項' ? 'primary' : 'default'}
                                        onClick={() => toggleFilter('已盤點品項')}
                                    >
                                        已盤點品項
                                    </Button>

                                <Input
                                    placeholder="搜尋..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ width: '100%' }}
                                />
                                <Button onClick={toggleMode}>
                    切換至 {mode === '小算盤模式' ? '直接輸入模式' : '小算盤模式'}
                </Button>
                              
                            </Space>
                            
                            <Table 
                            ref={tableRef}
                           
                            columns={columns}
                            dataSource={filteredProducts.filter(product => product.庫別 !== '未使用')}
                            rowKey="商品編號"
                            pagination={false}
                                size="small"
                                scroll={{ y: 550 }} // 設定 x 軸的最大寬度
                                rowRef={(ref, index) => rowRefs.current[index] = ref} // 将行引用绑定到一个数组

                            />

                                          
                    </div>
                </form>
            )}
            {/*calculatorVisible && (

                <Calculator
                    onCalculate={(result) => handleCalculate(result)}
                    onClose={handleCloseCalculator}
                    productName={currentProductName}
                    currentIndex={currentProductIndex} // 传递当前行的索引
                    setFocusToNextInput={setFocusToNextInput} // 传递设置焦点的函数

                />

            )*/}




            <footer style={{ zIndex: '9999', position: 'fixed', bottom: '0', left: '0', right: '0', textAlign: 'center', padding: '3px', backgroundColor: '#f5f5f5', borderTop: '1px solid #ccc' }}>
                <p style={{ margin: '0px' }}>© 2024-{new Date().getFullYear()} edc-pws.com. All rights reserved.</p>
            </footer>

        </>
    );
};

export default App;
