import React, { useState, useEffect, useRef } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { create, all } from 'mathjs'; // 引入 mathjs
import CalculatorButton from './CalculatorButton';
import './style/CalculatorButton.css';
const math = create(all, {}); // 創建 mathjs 實例

const Calculator = ({ onCalculate, onClose, productName, currentIndex, setFocusToNextInput }) => {
    const [input, setInput] = useState('');
    const calculatorRef = useRef(null);
    const inputRef = useRef(null); // 引用輸入框

    const handleNumberClick = (num) => {
        setInput((prev) => prev + num);
    };

    const handleOperatorClick = (operator) => {
        setInput((prev) => prev + operator);
    };

    const calculateResult = () => {
        try {
            const result = math.evaluate(input); // 使用 mathjs 計算結果
            onCalculate(result);
            setInput(result.toString()); // 將結果顯示在輸入框
            // 调用 setInputFocusIndex 来移动焦点
            setFocusToNextInput(currentIndex); // 传递当前输入框的索引
        } catch (error) {
            alert('計算錯誤：' + error.message);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 防止表單提交
            calculateResult();
        }
    };

    const handleClear = () => {
        setInput('');
    };


    return (
                       

        <div ref={calculatorRef} className="calc-container" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '20px', borderRadius: '10px', backgroundColor: '#f1f1f1' }}>
            <span>{productName} </span> {/* 显示商品名称 */}

            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', marginBottom: '5px' }} > {/* Added justifyContent */}
                <input className="calc-input" type="text" value={input} readOnly />
                <span onClick={() => setInput((prev) => prev.slice(0, -1))} class="material-symbols-outlined">
                    backspace
                </span>

                <span class="material-symbols-outlined" onClick={() => setInput('')}>
                    delete
                </span>
            </div>
            <div  style={{ display: 'flex', flexWrap: 'wrap' }}>
                <CalculatorButton type="execute" label="‰" onClick={() => handleOperatorClick('/1000')} />
                {['(', ')','+'].map(value => (
                    <CalculatorButton type="execute" key={value} label={value} onClick={() => handleOperatorClick(value)} />
                ))}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {['7', '8', '9',].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                <CalculatorButton type="execute" label="-" onClick={() => handleOperatorClick('-')} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {['4', '5', '6',].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                <CalculatorButton type="execute" label="×" onClick={() => handleOperatorClick('*')} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {['1', '2', '3'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                <CalculatorButton type="execute" label="÷" onClick={() => handleOperatorClick('/')} />

            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {['00', '0'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                {['.'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleOperatorClick(value)} />
                ))}
                <CalculatorButton type="execute" label="=" onClick={calculateResult} />
            </div>
  
        </div>
        

    );
};

export default Calculator;

